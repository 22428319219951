import {onAction, onPatch} from "mobx-state-tree"

export default self => {
  const debugChannel = new BroadcastChannel("debug")
  onAction(self, (call) => {
    call.name === "setError" && debugChannel.postMessage({
      type: 'sw',
      payload: {timestamp: Date.now(), error: call.args[0]}
    })
  })
  onPatch(self, (patch) => {
    console.log("✨", patch)
    debugChannel.postMessage({type: 'sw', payload: {timestamp: Date.now(), patch}})
  })
  const beforeDestroy = () => {
    debugChannel.close()
  }
  return {beforeDestroy}
}