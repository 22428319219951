import {applyPatch, destroy, detach, flow, types} from "mobx-state-tree"
import {userDataFromJWT} from "../../src/atoms/secure/jwt/app.js"
import debug from "./debug.js"
import {reaction} from "mobx"

const bc = new BroadcastChannel("channel")

const sw = types.model('service-worker', {
  context: types.maybe(types.model({version: types.string})),
  state: types.enumeration(['parsed', 'installing', 'installed', 'activating', 'activated', 'redundant'])
}).volatile(self => ({
  error: types.maybe(types.string),
  worker: null,
})).actions(self => ({
  onStateChange: (event) => self.state = event.target.state,
  onError: (error) => self.error = error
})).actions(self => {
  const mc = new MessageChannel()
  const init = (worker) => {
    self.worker = worker
    worker.addEventListener("statechange", self.onStateChange)
    worker.addEventListener('error', self.onError)
    worker.postMessage({op: "add", path: "/context/user", value: userDataFromJWT()}, [mc.port2])
    mc.port1.onmessage = ({data}) => applyPatch(self, data)
    bc.addEventListener('message', ({data}) => {
      if (data.name === "atom-secure") for (const patch of data.patches) patch.path === "/state" &&
      worker.postMessage({op: "replace", path: "/context/user", value: patch.value})
    })
    return self
  }
  return {
    for: init,
    beforeDestroy: () => {
      self.worker.removeEventListener("statechange", self.onStateChange)
      self.worker.removeEventListener('error', self.onError)
      self.worker = null

      mc.port1.close()
      mc.port2.close()
    }
  }
})

types.model({
  context: types.model({
    path: types.frozen(types.string),
    provided: types.boolean
  }),
  state: types.model({
    latest: types.maybe(sw),
    next: types.maybe(sw),
  }),
}).volatile(self => ({
  error: types.maybe(types.frozen({})),
})).actions(self => ({
  setCurrentState: (state) => self.state.latest.state = state,
  setNexState: (state) => self.state.next.state = state,
  setError: (error) => self.error = error.message,
  setWorker: (target, worker) => self.state[target] = sw.create({state: worker.state}).for(worker),

})).actions(self => ({
  afterCreate: flow(function* afterCreate() {
    if (!self.context.provided) {
      self.setError({message: "Браузер не поддерживает ServiceWorker"})
      return
    }
    try {
      const registration = yield navigator.serviceWorker.register(self.context.path, {type: 'module'})
      registration.onupdatefound = () => self.setWorker('next', registration.installing)
      registration.waiting && self.setWorker('next', registration.waiting)
      registration.active && self.setWorker('latest', registration.active)
    } catch (error) {
      console.error(error)
      self.setError(error)
    }
  }),
  updateSuccess: () => self.state.next.worker.postMessage({op: "test", path: "/state", value: "activate"}),
  updateWorker: () => {
    self.state.latest && destroy(self.state.latest)
    const next = self.state.next
    detach(next)
    self.state.latest = next
  },
})).actions(self => {
  let disposePing
  /** ServiceWorker активирован */
  const disposeReaction = reaction(() => self.state.latest?.state === 'activated', (activated) => {
      if (activated) {
      }
    }
  )
  /** Первая активация serviceWorker */
  const disposeFirstActivated = reaction(
    () => !self.state.latest && self.state.next?.state === 'activated',
    (activated) => activated && self.updateWorker()
  )
  /** Запрос на установку новой версии */
  const disposeUpdateWorker = reaction(
    () => self.state.next?.state === 'installed',
    (installed) => installed && self.updateSuccess()
  )
  /** Новая версия установлена и готова к активации */
  const disposeUpdatedWorker = reaction(
    () => self.state.latest?.state === 'redundant' && self.state.next?.state === 'activated',
    (updated) => updated && self.updateWorker()
  )
  const beforeDestroy = () => {
    disposeReaction()
    disposeUpdateWorker()
    disposeUpdatedWorker()
    disposeFirstActivated()
    clearInterval(disposePing)
  }
  return {beforeDestroy}
}).actions(debug).create({
  context: {
    path: '/sw.js',
    provided: "serviceWorker" in navigator,
    user: userDataFromJWT(),
  },
  state: {}
})