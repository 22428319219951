import {jwtDecode} from "jwt-decode"

/**
 * @typedef {Object} PayloadJWT - JWT payload
 * @property {string} id - идентификатор пользователя
 * @property {string} nickname - никнейм пользователя
 * @property {string} email - email пользователя
 * @property {string} role - роль пользователя
 * @property {boolean} active - активен ли пользователь
 * @property {number} iat - время создания
 * @property {number} exp - время жизни токена
 */
export const getToken = () => {
  const cookies = document.cookie.split("; ").reduce((acc, cookieStr) => {
    const [name, value] = cookieStr.split("=")
    acc[name] = value
    return acc
  }, {})
  return cookies["session"]
}
/**
 * @returns {PayloadJWT | null}
 */
export const userDataFromJWT = () => {
  const tokenData = getToken()
  if (!tokenData || tokenData === "not-valid") return null
  try {
    return jwtDecode(tokenData)
  } catch (error) {
    console.error("Failed to decode JWT:", error)
    return null
  }
}